import React from 'react';
import BackgroundEffect from '../Components/Header';
import Reasons from '../Components/Reasons';
import DateOptions from '../Components/DateOptions';

const Home = () => {
  return (
    <>
      <BackgroundEffect />
      <Reasons />
      <DateOptions />
    </>
  );
};

export default Home;
