import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Heart, ArrowLeft, ArrowRight, Sparkles } from 'lucide-react';

const dateOptions = [
  { Image: 'https://i.ibb.co/rMyXfhx/woodlands.jpg', Restaurant_Name: 'Pregame and then Graveyard', description: 'Perhaps even going to the Wissahickon if we can make it there 🌙' },
  { Image: 'https://i.ibb.co/S0gNQzn/elvez.jpg', Restaurant_Name: 'El Vez', description: 'The only place on my Beli. They have very good nachos 🌮' },
  { Image: 'https://i.ibb.co/wMbB6mR/eefea3-9e640993c285466d86a7cf70222f128c-mv2.png', Restaurant_Name: 'Soulful D\'lites', description: 'Since I heard you are from Nashiville I guess?? 🚀' },
  { Image: 'https://i.ibb.co/xC7KhFS/625b10a58137b364b18df2ea-i-Stock-94179607.jpg', Restaurant_Name: 'Infiltrate Philly Housing Market', description: 'I think people would be willing to offer us a 5% downpayment 🏠' },
  { Image: 'https://i.ibb.co/0fZSz2L/files.jpg', Restaurant_Name: 'Explore the DP\'s Upstair File Rooms', description: 'Bro have you been in that shit it\'s lowkey kinda crazy 📁' },
  { Image: 'https://i.ibb.co/34q2K3f/renatas.jpg', Restaurant_Name: 'Renata\'s Kitchen', description: 'Kinda basic but like this is a classic 🍽️' },
];

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const shine = keyframes`
  0% { transform: translateX(-100%) rotate(45deg); }
  100% { transform: translateX(100%) rotate(45deg); }
`;

const gradientBg = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const Container = styled.div`
  background: linear-gradient(-45deg, #ff9a9e, #fad0c4, #fad0c4, #a18cd1);
  background-size: 400% 400%;
  animation: ${gradientBg} 15s ease infinite;
  min-height: 100vh;
  padding: 3rem 1rem;
`;

const ContentWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
`;

const TitleText = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;

  @media (max-width: 600px) {
    font-size: 1.8rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: white;
  opacity: 0.9;
  margin-top: 1rem;
`;

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const DateOption = styled.div`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
  cursor: pointer;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);

    &:before {
      content: '';
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
      background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 250px;
`;

const RestaurantImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  ${DateOption}:hover & {
    transform: scale(1.1);
  }
`;

const ContentOverlay = styled.div`
  padding: 1.5rem;
  background: rgba(0, 0, 0, 0.7);
  color: white;
`;

const RestaurantName = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  background: linear-gradient(45deg, #FF0000, #FF7F00, #FFFF00, #00FF00, #0000FF, #4B0082, #9400D3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 400% 400%;
  animation: ${gradientBg} 5s ease infinite;
`;

const Description = styled.p`
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9);
  margin-top: 0.5rem;
`;

const FloatingHeart = styled(Heart)`
  position: absolute;
  animation: ${float} 3s ease-in-out infinite;
  color: #ff6b6b;
`;

const DateOptions = () => {
  return (
    <Container>
      <ContentWrapper>
        <TitleContainer>
          <FloatingHeart style={{ top: '-20px', left: '20%' }} size={32} />
          <TitleText>
            I will take you anywhere within like a $36pp budget
          </TitleText>
          <Subtitle>(I'm working on it 😔)</Subtitle>
          <FloatingHeart style={{ top: '-20px', right: '20%' }} size={32} />
        </TitleContainer>

        <OptionsContainer>
          {dateOptions.map((option, index) => (
            <DateOption key={index}>
              <ImageContainer>
                <RestaurantImage src={option.Image} alt={option.Restaurant_Name} />
              </ImageContainer>
              <ContentOverlay>
                <RestaurantName>{option.Restaurant_Name}</RestaurantName>
                <Description>{option.description}</Description>
              </ContentOverlay>
            </DateOption>
          ))}
        </OptionsContainer>
      </ContentWrapper>
    </Container>
  );
};

export default DateOptions;