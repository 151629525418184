import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Heart, Sparkles, Star } from 'lucide-react';

const reasons = [
  { 
    reason: 'Thoughtful??',
    description: 'I like to do nice things and be nice to people and make stuff for my friends.',
    emoji: '💝'
  },
  {
    reason: 'Silly',
    description: 'I love to not take life that seriously and what not and perhaps on occasion make good jokes.',
    emoji: '😋'
  },
  {
    reason: 'Exploratory!',
    description: 'I love going on adventures and doing fun stuff and am always down to explore the world.',
    emoji: '🌎'
  },
  {
    reason: 'I like to learn',
    description: 'And I think you do too and that is something that I really respect about you 💯💯',
    emoji: '📚'
  }
];

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const gradientMove = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const shine = keyframes`
  0% { transform: translateX(-100%) rotate(45deg); }
  100% { transform: translateX(100%) rotate(45deg); }
`;

const BackgroundContainer = styled.div`
  min-height: 100vh;
  background: linear-gradient(-45deg, #fee9e9, #f7e8f8, #e8f0fe, #e8fef1);
  background-size: 400% 400%;
  animation: ${gradientMove} 15s ease infinite;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`;

const TitleContainer = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 3rem;
`;

const TitleText = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  background: linear-gradient(45deg, #ff6b6b, #a36fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 1rem 0;
  position: relative;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ReasonBox = styled.div`
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

    &:before {
      content: '';
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
      background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      animation: ${shine} 1.5s ease forwards;
    }
  }
`;

const EmojiContainer = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
  animation: ${float} 3s ease-in-out infinite;
`;

const ReasonText = styled.h2`
  font-size: 1.8rem;
  font-weight: bold;
  background: linear-gradient(45deg, #ff6b6b, #a36fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0.5rem 0;
`;

const DescriptionText = styled.p`
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
`;

const FloatingIcon = styled.div`
  position: absolute;
  animation: ${float} 3s ease-in-out infinite;
  color: #ff6b6b;
`;

const Reasons = () => {
  return (
    <BackgroundContainer>
      <ContentWrapper>
        <TitleContainer>
          <FloatingIcon style={{ top: '-20px', left: '10%' }}>
            <Heart size={32} />
          </FloatingIcon>
          <TitleText>Go on a date with me because...</TitleText>
          <FloatingIcon style={{ top: '-20px', right: '10%' }}>
            <Sparkles size={32} />
          </FloatingIcon>
        </TitleContainer>
        
        <GridContainer>
          {reasons.map((reason, index) => (
            <ReasonBox key={index}>
              <EmojiContainer>{reason.emoji}</EmojiContainer>
              <ReasonText>{reason.reason}</ReasonText>
              <DescriptionText>{reason.description}</DescriptionText>
            </ReasonBox>
          ))}
        </GridContainer>
      </ContentWrapper>
    </BackgroundContainer>
  );
};

export default Reasons;